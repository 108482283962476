<!--
  eslint-disable vuejs-accessibility/click-events-have-key-events
  eslint-disable vuejs-accessibility/no-static-element-interactions  | TODO fix lint errors https://github.com/vue-a11y/eslint-plugin-vuejs-accessibility/tree/main/docs
-->
<template>
  <div
    class="z-modal fixed bottom-0 left-0 right-0 top-0 overflow-scroll bg-black bg-opacity-70"
    data-test="modal-wrapper"
    @click.self="autoclose ? close() : $emit('bg-click', modalId)"
  >
    <div
      ref="modalBody"
      role="dialog"
      :aria-label="modalTitle ? modalTitle : $t('common.dialog')"
      class="max-h-full-8 border-primary-200 mx-auto my-4 flex w-full max-w-2xl flex-col gap-y-6 rounded-3xl border bg-white py-6 shadow-xl"
    >
      <header class="border-base mx-6 flex-none border-b pb-4">
        <slot name="header-icon" />
        <span role="status" data-test="modal-title" class="text-primary-400 text-xs font-bold uppercase tracking-wide">
          {{ modalTitle }}
        </span>
      </header>
      <div class="min-h-32 my-4 flex flex-grow flex-col gap-y-6 overflow-y-scroll px-6">
        <div v-if="modalPrompt" class="flex flex-col">
          <span data-test="modal-prompt" class="text-primary-600 text-2xl font-bold">{{ modalPrompt }}</span>
          <span data-test="modal-description" class="text-muted text-sm">{{ modalDescription }}</span>
        </div>
        <slot v-bind="{ modalId, close }" />
      </div>
      <footer
        v-if="isSlotUsed($slots, 'footer')"
        :class="[
          'border-base mx-6 flex flex-none content-center justify-center gap-x-2.5 border-t pt-4',
          footerClasses,
        ]"
      >
        <slot name="footer" v-bind="{ close }" />
      </footer>
    </div>
  </div>
</template>

<script>
import { closeModal } from 'vue_features/shared/composables/use_global_modals'
import { ref } from 'vue'
import useFocusTrap from 'vue_features/shared/composables/use_focus_trap'
import { isSlotUsed } from 'vue_features/shared/helpers/slot_helpers'

export default {
  name: 'Modal',
  props: {
    modalId: { type: String, required: true },
    modalTitle: { type: String, default: '' },
    modalPrompt: { type: String, default: '' },
    modalDescription: { type: String, default: '' },
    autoclose: { type: Boolean, default: true },
    footerClasses: { type: [String, Array, Object], default: '' },
  },
  setup(props) {
    const modalBody = ref()
    useFocusTrap(modalBody)

    return { close: () => closeModal(props.modalId), modalBody, isSlotUsed }
  },
}
</script>
